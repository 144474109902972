import React, { useState } from "react";
import { graphql } from "gatsby";
import BgImage from "gatsby-background-image";
import { Row, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Layout from "@components/Layout";
import Video from "@components/Video";
import { materialVideos } from "@utils/static-data";
import { BahanPageProps } from "src/types";

import styles from "@views/Bahan/bahan.module.scss";

export const pageQuery = graphql`
  query BahanQuery {
    bgDesktop: file(relativePath: { eq: "bg/desktop/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgMobile: file(relativePath: { eq: "bg/mobile/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 625, maxHeight: 1104, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function Bahan(props: BahanPageProps) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [activeTabs, setActiveTabs] = useState<number>(0);
  const { bgDesktop, bgMobile } = props.data;

  // Art directing image
  const bg = [
    bgMobile.childImageSharp.fluid,
    {
      ...bgDesktop.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  const renderMaterialDescList = () => {
    return materialVideos.map((data, idx) => (
      <div
        key={idx}
        className={`${styles.listItem} ${
          idx === activeTabs ? styles.active : ``
        }`}
        onClick={() => setActiveTabs(idx)}
      >
        <span>{idx + 1}. &nbsp;</span>
        <span>{data.title}</span>
      </div>
    ));
  };

  const renderMaterialVideo = () => {
    let title = materialVideos[activeTabs].title;
    let link = materialVideos[activeTabs].link;

    return (
      <>
        <p>{title.replace("Video Penjelasan ", "")}</p>
        <div className={styles.videoContainer}>
          <Video link={link} quality="hq" imgStyle={{ borderRadius: 0 }} />
        </div>
      </>
    );
  };

  return (
    <Layout noFooter>
      <BgImage fluid={bg} alt="background" className={styles.bahan}>
        <div className={styles.innerBahan}>
          <Row className={styles.title}>
            <h1>
              <span style={{ color: "#db9a09" }}>Penjelasan</span>{" "}
              {isDesktop ? `` : <br />} Macam-macam Bahan
            </h1>
          </Row>
          <Row className={styles.content}>
            <Col md={6} className={styles.video}>
              {renderMaterialVideo()}
            </Col>
            <Col md={6} className={styles.list}>
              {renderMaterialDescList()}
            </Col>
          </Row>
        </div>
      </BgImage>
    </Layout>
  );
}
